// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CodeMirror * {
  line-height: 1.6;
  font-family: Menlo, Consolas, "DejaVu Sans Mono", "Ubuntu Mono", monospace;
  font-size: 12px;
  max-height: 90vh;
}

@media (max-width: 767.98px) {
  .CodeMirror * {
    max-height: 50vh;
  }
}

.dropdown-list-item.active-tick a::after {
  font-family: "Font Awesome 6 Pro";
  content: "\\f00c";
  color: #0d6efd;
  float: right;
  display: none;
  width: 1rem;
  height: 1.5rem;
}

.dropdown-list-item.active-tick svg {
  color: #0d6efd;
  float: right;
  width: 1rem;
  height: 1.5rem;
}

a .card-body {
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

a .card-body:hover {
  background-color: #f8f9fa;
}

.svg-inline--fa.fa-fw {
  width: 1em;
}
`, "",{"version":3,"sources":["webpack://./Public/css/common.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,0EAA0E;EAC1E,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE,iCAAiC;EACjC,gBAAgB;EAChB,cAAc;EACd,YAAY;EACZ,aAAa;EACb,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,oBAAoB;EACpB,qBAAqB;EACrB,wBAAwB;EACxB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".CodeMirror * {\n  line-height: 1.6;\n  font-family: Menlo, Consolas, \"DejaVu Sans Mono\", \"Ubuntu Mono\", monospace;\n  font-size: 12px;\n  max-height: 90vh;\n}\n\n@media (max-width: 767.98px) {\n  .CodeMirror * {\n    max-height: 50vh;\n  }\n}\n\n.dropdown-list-item.active-tick a::after {\n  font-family: \"Font Awesome 6 Pro\";\n  content: \"\\f00c\";\n  color: #0d6efd;\n  float: right;\n  display: none;\n  width: 1rem;\n  height: 1.5rem;\n}\n\n.dropdown-list-item.active-tick svg {\n  color: #0d6efd;\n  float: right;\n  width: 1rem;\n  height: 1.5rem;\n}\n\na .card-body {\n  -o-transition: 0.5s;\n  -ms-transition: 0.5s;\n  -moz-transition: 0.5s;\n  -webkit-transition: 0.5s;\n  transition: 0.5s;\n}\n\na .card-body:hover {\n  background-color: #f8f9fa;\n}\n\n.svg-inline--fa.fa-fw {\n  width: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
